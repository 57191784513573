<!--
  PACKAGE_NAME : src\pages\report\ewm
  FILE_NAME : event-type-setting
  AUTHOR : hpmoon
  DATE : 2024-10-25
  DESCRIPTION : 보고서 > 예측 > 예측 조회 > 이벤트 유형 선택
-->
<template>
  <DxPopup
      :show-title="true"
      :title="modal.title"
      :min-width="modal.minWidth"
      :width="modal.width"
      :min-height="modal.minHeight"
      :height="modal.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :close-on-outside-click="modal.closeOnOutsideClick"
      :show-close-button="modal.showCloseButton"
      :visible="isOpen"
      @hiding="closeModal"
  >
    <template #content>
      <div>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label5">{{ $_msgContents('FORECAST.WORD.EVENT_DATE', {defaultValue: '이벤트 날짜'}) }}</label>
              </th>
              <td>
                {{ formatDt(formData.dt_date) }}
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_msgContents('FORECAST.WORD.EVENT_TYPE', {defaultValue: '이벤트 유형'}) }} <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxSelectBox
                  :placeholder="$_msgContents('COMPONENTS.SELECT', { defaultValue: '선택' })"
                  :data-source="$_getCode('forecasting_event')"
                  display-expr="codeNm"
                  value-expr="codeValue"
                  v-model="formData.event.code"
                  :styling-mode="config.stylingMode"
                  :show-clear-button="false"
                  width="200px"
                  :height="30"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="content-info-box01 pad_top10 mar_to10">
          {{ $_msgContents('FORECAST.MESSAGE.FORECAST_EVENT_INFO_MESSAGE1', {defaultValue: '예측값과 실제값 사이에 큰 차이가 발생하는 경우에는 이벤트로 간주됩니다.'}) }}<br/>
          {{ $_msgContents('FORECAST.MESSAGE.FORECAST_EVENT_INFO_MESSAGE3', {defaultValue: '이벤트의 원인을 분석하고 유형별로 관리하면, 콜 예측의 정확성을 더욱 높일 수 있습니다.'}) }}
        </div>
      </div>
    </template>

    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            validationGroup: 'validationGroupName',
            onClick: saveModal,
          }"
    />
    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import { DxSelectBox } from "devextreme-vue/select-box";
import { isEmpty } from "@/plugins/common-lib";

export default {
  components: {
    DxSelectBox,
    DxPopup,
    DxToolbarItem,
  },

  props: {
    isOpen: Boolean,
    data: Object,
  },

  watch: {
    formData: {
      handler(val) {
        const filterData = val;
        if (filterData) {
          this.$emit('input', filterData);
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      modal: {
        title: this.$_msgContents('FORECAST.WORD.SELECT_EVENT_TYPE', { defaultValue: '이벤트 유형 선택' }),
        minWidth: '580',
        width: '580',
        minHeight: '290',
        height: '290',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        closeOnOutsideClick: false,
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      formData: {
        dt_date: null,
        event: {
          id: null,
          code: null
        },
      },
    };
  },

  computed: {
  },

  methods: {
    /** @description 모달 저장 */
    async saveModal(e) {
      if (isEmpty(this.formData.event?.code)) {
        this.$_Msg(this.$_msgContents('FORECAST.MESSAGE.PLEASE_SELECT_EVENT_TYPE', { defaultValue: '이벤트 유형을 선택하여 주세요.' }));
        return;
      }

      if (await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
        const payload = {
          actionname: 'FORECAST_EVENT_SAVE',
          data:
            [
              {
                dt_date: this.formatDt(this.formData.dt_date),
                code: this.formData.event?.code
              }
            ],
          loading: true,
        };

        if (!isEmpty(this.data.event?.id)) {
          payload.data[0].id = this.data.event.id
        }

        const res = await this.CALL_FORECAST_API(payload);
        if (res.status === 200) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        }
      }
    },

    /** @description 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },

    /** @description 날짜 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD');
    },

    /** @description 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      this.formData.dt_date = this.data.dt_date;
      this.formData.event.id = this.data.event?.id ?? null;
      this.formData.event.code = (this.data.event?.code ?? null) === 'fail' ? null : this.data.event?.code;
    },
  },

  created() {
    this.createdData();
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>